<template>
    <div class="flex h-16 px-4 border-b items-center">
        <p class="font-light">
            {{ title }}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type:    String,
            default: '',
        },
    },
};
</script>
